/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-tabs, no-mixed-spaces-and-tabs, max-len */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FaPlus } from 'react-icons/fa';
import LoadingBox from 'components/LoadingBox';
import axios from 'axios';
import { endpoints } from 'api';
import * as currentOrderActions from '../../actions/currentOrderActions';

import CurrentOrder from '../CurrentOrder';
import IconButton from '../../components/IconButton';
import FilePicker from '../../components/FilePicker';
import DropDown from '../../components/DropDown';
import InputOnBlur from '../../components/InputOnBlur';
import Layout from '../../components/Layout';

import getBase64 from '../../helpers/getBase64';
import './style.scss';

class Upload extends React.Component {
  filePickerFileTypes = '.pdf,.docx, .jpg, .png';

  existingFileTypes = new RegExp('^.*.(jpg|JPG|pdf|PDF|png|PNG)$');

  compositionFileTypes = new RegExp('^.*.(jpg|JPG|docx|pdf|PDF|png|PNG)$');

  constructor(props) {
    super(props);
    this.state = {
      type: undefined,
      loading: true,
      searchDropDownOpen: false,
      searchPostalCode: '',
      municipalities: [],
      existing: {
        type: 'existing',
        description: 'matériel fini',
        advertisingTypeCode: 'RTU',
      },
      composition: {
        type: 'composition',
        description: 'Composition',
        advertisingTypeCode: 'TBD',
        companyOrClientName: '',
        companyOrClientNameError: '',
        firstName: '',
        firstNameError: '',
        lastName: '',
        lastNameError: '',
        comment: '',
        commentError: '',
        phoneNumber: '',
        phoneNumberError: '',
        email: '',
        websiteLink: '',
        facebookLink: '',
        openingHours: '',
        address: {
          street: '',
          streetError: '',
          number: '',
          numberError: '',
          municipalityId: '',
          municipalityIdError: '',
        },
      },
      existingFile: undefined,
      existingFileName: undefined,
      existingFileError: false,
      existingFileErrorMessage: '',
      currentCompositionFiles: [],
      compositionFiles: [
        {
          file: undefined,
          fileName: undefined,
          error: false,
          errorMessage: '',
        },
        {
          file: undefined,
          fileName: undefined,
          error: false,
          errorMessage: '',
        },
      ],
    };
  }

  componentDidMount = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    const { materialType, orderData } = this.props.currentOrder;
    const {
      existingFile, existingFileName, compositionFiles, currentCompositionFiles,
    } = this.state;

    try {
      const result = await axios.get(endpoints.getMunicipalities);

      this.setState({ loading: false, municipalities: result.data });
    } catch (error) {
      // TODO: display error
      this.setState({ loading: false });
    }

    if (!materialType) {
      this.switchSelectedSolution('existing');
      return;
    }

    const { type } = materialType;
    // eslint-disable-next-line react/destructuring-assignment
    const { materialFiles } = this.props.currentOrder;

    switch (type) {
      case 'existing':
        this.setState({
          type: 'existing',
          existingFile: materialFiles.length
            ? materialFiles.map(file => (file.inputFile))[0]
            : existingFile,
          existingFileName: materialFiles.length
            ? materialFiles.map(file => (file.name))[0]
            : existingFileName,
        });
        break;

      case 'composition':
        this.setState({
          type: 'composition',
          composition: materialType,
          compositionFiles: materialFiles.length
            ? materialFiles.map(file => ({
              file: file.inputFile,
              fileName: file.name,
              error: false,
              errorMessage: '',
            }))
            : compositionFiles,
          currentCompositionFiles: materialFiles.length
            ? materialFiles.map(file => ({
              inputFile: file.inputFile,
              name: file.name,
              type: file.type,
              file: file.file,
            }))
            : currentCompositionFiles,
        });
        break;

      default:
        break;
    }

    if (materialFiles.length) {
      this.setState({
        compositionFiles: materialFiles.map(file => ({
          file: file.inputFile,
          fileName: file.name,
          error: false,
          errorMessage: '',
        })),
      });
    }

    // Set postal code display value
    if (type === 'composition' && orderData.advertising.address.municipalityId) {
      const mun = this.state.municipalities.find(m => m.id === orderData.advertising.address.municipalityId);
      this.setState({ searchPostalCode: `${mun.name} - ${mun.postalCode}` });
    }
  }

  handleCompositionFileInputChange = async ({ file }, index) => {
    const {
      clearCurrentOrderError,
      setMaterialType,
      setMaterialFiles,
    } = this.props.currentOrderActions; // eslint-disable-line react/destructuring-assignment
    let compositionFiles;

    const { existing, composition, currentCompositionFiles } = this.state;

    if (!file) return;
    if (!this.compositionFileTypes.test(file.name)) {
      // eslint-disable-next-line react/destructuring-assignment
      compositionFiles = Object.assign([], this.state.compositionFiles, {
        [index]: {
          file: undefined,
          fileName: file.name,
          error: true,
          errorMessage: 'merci de choisir un format de fichier authorisé...',
        },
      });
      this.setState({ compositionFiles });

      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.type !== 'composition') return;
      setMaterialType(existing);
      setMaterialFiles(compositionFiles.filter(f => f.file !== undefined));
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      try {
        const fileData = await getBase64(file);
        currentCompositionFiles.push({
          inputFile: file, file: fileData, name: file.name, type: file.type,
        });
        compositionFiles = Object.assign([], this.state.compositionFiles, {
          [index]: {
            file: fileData,
            fileName: file.name,
            error: false,
            errorMessage: '',
          },
        });
        this.setState({ compositionFiles, type: 'composition', currentCompositionFiles });

        clearCurrentOrderError();
        setMaterialType(composition);
        setMaterialFiles(currentCompositionFiles);
      } catch (error) {
        compositionFiles = Object.assign([], this.state.compositionFiles, {
          [index]: {
            error: true,
            errorMessage: 'Il y a eu une erreur durant l\'upload',
          },
        });
        this.setState({ compositionFiles, type: 'composition' });
      }
    }
  }

  handleExistingFileInputChange = async ({ file }) => {
    const {
      setMaterialFiles,
      setMaterialType,
    } = this.props.currentOrderActions; // eslint-disable-line react/destructuring-assignment
    const { existing } = this.state;
    if (!file) return;
    if (!this.existingFileTypes.test(file.name)) {
      this.setState({
        existingFileError: true,
        existingFileErrorMessage:
          'merci de choisir un format de fichier authorisé...',
      });
      return;
    }
    try {
      const fileData = await getBase64(file);
      this.setState({
        existingFile: fileData,
        existingFileName: file.name,
        existingFileError: false,
        type: 'existing',
      });
      setMaterialFiles([{
        inputFile: file, file: fileData, name: file.name, type: file.type,
      }]);
      setMaterialType(existing);
    } catch (error) {
      this.setState({
        existingFileError: true,
        existingFileErrorMessage:
          'Il y a eu une erreur durant l\'upload',
        type: 'existing',
      });
    }
  }

  saveComposition = async () => {
    const { type, composition, currentCompositionFiles } = this.state;
    const { setMaterialType, setMaterialFiles } = this.props.currentOrderActions;
    if (type !== 'composition') return;
    delete composition.companyOrClientNameError;
    // delete composition.firstNameError;
    // delete composition.lastNameError;
    delete composition.commentError;
    delete composition.address.streetError;
    delete composition.address.numberError;
    delete composition.address.municipalityIdError;
    setMaterialType(composition);
    // Reset materialFiles if empty
    if (!currentCompositionFiles.length) setMaterialFiles([]);
    if (this.validateComposition()) throw new Error('Composition invalide');
  }

  validateComposition() {
    const { composition } = this.state;
    let hasError = false;
    if (composition.companyOrClientName === '') {
      hasError = true;
      composition.companyOrClientNameError = true;
    }
    // if (composition.firstName === '') {
    //   hasError = true;
    //   composition.firstNameError = true;
    // }
    // if (composition.lastName === '') {
    //   hasError = true;
    //   composition.lastNameError = true;
    // }
    if (composition.comment === '') {
      hasError = true;
      composition.commentError = true;
    }
    if (composition.address.street === '') {
      hasError = true;
      composition.address.streetError = true;
    }
    if (composition.address.number === '') {
      hasError = true;
      composition.address.numberError = true;
    }
    if (composition.address.municipalityId === '') {
      hasError = true;
      composition.address.municipalityIdError = true;
    }
    this.setState({
      composition: { ...composition },
    });
    return hasError;
  }

  // Add a new file Uploader
  addFileUploader() {
    this.setState({
      compositionFiles: [
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.compositionFiles, // eslint-disable-line react/destructuring-assignment
        {
          file: undefined,
          fileName: undefined,
          error: false,
          errorMessage: '',
        },
      ],
    });
  }

  // Remove the indexed file Uploader
  removeFileUploader(index) {
    const {
      setMaterialType,
      setMaterialFiles,
    } = this.props.currentOrderActions; // eslint-disable-line react/destructuring-assignment

    const { composition } = this.state;

    // eslint-disable-next-line react/destructuring-assignment
    const compositionFiles = this.state.compositionFiles.filter(
      (uploader, i) => i !== index,
    );
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state.compositionFiles[index].file) {
      this.setState({ compositionFiles });
    } else {
      this.setState({ compositionFiles, type: 'composition' });

      // Update Store
      setMaterialType(composition);
      setMaterialFiles(compositionFiles.filter(f => f.file !== undefined));
    }
  }

  // Change the selected solution and update store on header click
  switchSelectedSolution(type) {
    const {
      clearCurrentOrderError,
      setMaterialType,
      setMaterialFiles,
    } = this.props.currentOrderActions; // eslint-disable-line react/destructuring-assignment

    // Update current state
    this.setState({
      type,
      existingFile: undefined,
      existingFileName: undefined,
      currentCompositionFiles: [],
      compositionFiles: [
        {
          file: undefined,
          fileName: undefined,
          error: false,
          errorMessage: '',
        },
        {
          file: undefined,
          fileName: undefined,
          error: false,
          errorMessage: '',
        },
      ],
    });

    // Update the store
    setMaterialType(this.state[type]); // eslint-disable-line react/destructuring-assignment
    clearCurrentOrderError();
    setMaterialFiles([]);
  }


  // EXISTING MATERIAL
  renderExisting() {
    const { existingFileName, existingFileError, existingFileErrorMessage } = this.state;
    return (
      <>
        <div className="hint">
          Chargez votre matériel terminé. Formats PDF, JPG, PNG acceptés.
        </div>
        <div className="file-picker-container">
          <FilePicker
            fileTypes={this.filePickerFileTypes}
            fileName={existingFileName}
            handleFileInputChange={e => this.handleExistingFileInputChange(e)}
            buttonText="TRANSFÉRER"
            error={existingFileError}
            errorMessage={existingFileErrorMessage}
            placeHolder="choisissez un fichier..."
            fileOpenerText="choisir un fichier"
          />
        </div>
      </>
    );
  }

  // ASK FOR COMPOSITION
  renderComposition() {
    const {
      municipalities,
      searchDropDownOpen,
      searchPostalCode,
      composition,
      compositionFiles,
    } = this.state;
    return (
      <>
        <div className="hint">
          Notre service réalise votre annonce. Remplissez le formulaire ci-dessous. Nous vous enverrons un bon à tirer pour validation avant publication.
        </div>

        <div className="comment-container">
          <InputOnBlur
            name="Nom de l&apos;activité*"
            value={composition.companyOrClientName}
            error={composition.companyOrClientNameError}
            onChange={e => this.setState({
              composition: {
                ...composition, companyOrClientName: e.target.value,
              },
            })}
            onBlur={e => this.setState({
              composition: {
                ...composition, companyOrClientNameError: !e.target.value,
              },
            })}
          />
          {/* <InputOnBlur
            name="Prénom*"
            value={composition.firstName}
            error={composition.firstNameError}
            onChange={e => this.setState({
              composition: {
                ...composition, firstName: e.target.value,
              },
            })}
            onBlur={e => this.setState({
              composition: {
                ...composition, firstNameError: !e.target.value,
              },
            })}
          />
          <InputOnBlur
            name="Nom*"
            value={composition.lastName}
            error={composition.lastNameError}
            onChange={e => this.setState({
              composition: {
                ...composition, lastName: e.target.value,
              },
            })}
            onBlur={e => this.setState({
              composition: {
                ...composition, lastNameError: !e.target.value,
              },
            })}
          /> */}
          <InputOnBlur
            name="N° de téléphone"
            value={composition.phoneNumber}
            error={composition.phoneNumberError}
            onChange={e => this.setState({
              composition: {
                ...composition, phoneNumber: e.target.value,
              },
            })}
          />
          <InputOnBlur
            name="E-mail"
            value={composition.email}
            onChange={e => this.setState({
              composition: { ...composition, email: e.target.value },
            })}
          />
          <InputOnBlur
            name="Site web"
            value={composition.websiteLink}
            onChange={e => this.setState({
              composition: {
                ...composition, websiteLink: e.target.value,
              },
            })}
          />
          <InputOnBlur
            name="Facebook"
            value={composition.facebookLink}
            onChange={e => this.setState({
              composition: {
                ...composition, facebookLink: e.target.value,
              },
            })}
          />
          <InputOnBlur
            name="Horaires"
            value={composition.openingHours}
            onChange={e => this.setState({
              composition: {
                ...composition, openingHours: e.target.value,
              },
            })}
          />
          <InputOnBlur
            name="Rue*"
            value={composition.address.street}
            error={composition.address.streetError}
            onChange={e => this.setState({
              composition: {
                ...composition,
                address: {
                  ...composition.address, street: e.target.value,
                },
              },
            })}
            onBlur={e => this.setState({
              composition: {
                ...composition,
                address: {
                  ...composition.address, streetError: !e.target.value,
                },
              },
            })}
          />
          <InputOnBlur
            name="Numéro*"
            value={composition.address.number}
            error={composition.address.numberError}
            onChange={e => this.setState({
              composition: {
                ...composition,
                address: {
                  ...composition.address, number: e.target.value,
                },
              },
            })}
            onBlur={e => this.setState({
              composition: {
                ...composition,
                address: {
                  ...composition.address, numberError: !e.target.value,
                },
              },
            })}
          />
          <InputOnBlur
            name="Code postal*"
            value={searchPostalCode}
            error={composition.address.municipalityIdError}
            onClick={() => this.setState({ searchDropDownOpen: true })}
            onChange={e => this.setState({
              composition: {
                ...composition,
                address: {
                  ...composition.address, municipalityId: e.target.value,
                },
              },
              searchPostalCode: e.target.value,
              searchDropDownOpen: true,
            })}
            onBlur={e => this.setState({
              composition: {
                ...composition,
                address: {
                  ...composition.address, municipalityIdError: !e.target.value,
                },
              },
            })}
          />
          <DropDown
            noHead
            onClickOutside={() => this.setState({ searchDropDownOpen: false })}
            isOpen={searchDropDownOpen}
            overlay
          >
            {municipalities.filter(m => m.postalCode === Number(searchPostalCode)).map((option, index) => (
              <div
                className="postalCode-search-dropdown-option"
                key={index} // eslint-disable-line react/no-array-index-key
                onClick={() => {
                  this.setState({
                    searchDropDownOpen: false,
                    searchPostalCode: `${option.postalCode} - ${option.name}`,
                    composition: { ...composition, address: { ...composition.address, municipalityId: option.id } },
                  });
                }}
                onKeyPress={() => {
                  this.setState({
                    searchDropDownOpen: false,
                    searchPostalCode: `${option.postalCode} - ${option.name}`,
                    composition: { ...composition, address: { ...composition.address, municipalityId: option.id } },
                  });
                }}
                role="button"
                tabIndex="0"
              >
                {`${option.name} - ${option.postalCode}`}
              </div>
            ))}
          </DropDown>
          <InputOnBlur
            textarea
            name="Commentaires*"
            error={composition.commentError}
            value={composition.comment}
            onChange={e => this.setState({
              composition: {
                ...composition, comment: e.target.value,
              },
            })}
            onBlur={e => this.setState({
              composition: {
                ...composition, commentError: !e.target.value,
              },
            })}
          />
        </div>

        <div className="hint">
          Vous disposez déjà de quelques fichiers pour votre composition?
          Chargez-les.
        </div>

        {compositionFiles.map((fileUploader, index) => (
          <div className="file-picker-container" key={index}>
            <FilePicker
              fileTypes={this.filePickerFileTypes}
              fileName={fileUploader.fileName}
              handleFileInputChange={e => this.handleCompositionFileInputChange(e, index)
                }
              buttonText="TRANSFÉRER"
              error={fileUploader.error}
              errorMessage={fileUploader.errorMessage}
              placeHolder="choisissez un fichier..."
              fileOpenerText="choisir fichier"
            />

            {index > 0 ? (
              <div className="icon-container">
                <IconButton
                  handleClick={() => this.removeFileUploader(index)}
                />
              </div>
            ) : null}
          </div>
        ))}
        <div className="add-file-container">
          <div
            className="icon"
            onClick={() => this.addFileUploader()}
            onKeyPress={() => this.addFileUploader()}
            tabIndex="0"
            role="button"
          >
            <FaPlus />
          </div>
          <div
            className="text"
            onClick={() => this.addFileUploader()}
            onKeyPress={() => this.addFileUploader()}
            tabIndex="0"
            role="button"
          >
            Chargez plus de fichiers
          </div>
        </div>
      </>
    );
  }

  render() {
    const { type, loading } = this.state;

    return (
      <Layout
        cover="/assets/images/covers/reservation.jpg"
        title="Réservation"
      >
        <div className="upload-container">
          {/* SUBTITLE */}
          <div className="upload-subtitle">
            <div className="upload-subtitle-content">
            Chargez votre matériel ou demandez une composition
            à notre graphiste.
            </div>
          </div>
          {/* MAIN CONTENT */}
          <div className="upload-main">
            {loading ? (
              <div className="loading-container">
                <LoadingBox loading />
              </div>
            ) : (
              <>
                <div className="solutions-container">
                  <div className="solution-type-container">
                    {/* Upload a composition */}
                    <DropDown
                      name={"J'ai mon matériel prêt à publier"}
                      headerCheckBoxes
                      isOpen={type === 'existing'}
                      handleHeaderClick={() => this.switchSelectedSolution('existing')
                      }
                    >
                      {[
                        <div className="solution-type-content" key={0}>
                          {this.renderExisting()}
                        </div>,
                      ]}
                    </DropDown>
                  </div>

                  {/* ASK FOR A composition */}
                  <div className="solution-type-container">
                    <DropDown
                      name="Je demande une composition"
                      headerCheckBoxes
                      isOpen={type === 'composition'}
                      handleHeaderClick={() => this.switchSelectedSolution('composition')
                      }
                    >
                      {[
                        <div className="solution-type-content" key={0}>
                          {this.renderComposition()}
                        </div>,
                      ]}
                    </DropDown>
                  </div>
                </div>

                {/* Order & Help */}
                <div className="order-help-container">
                  <div className="order-help-order">
                    <CurrentOrder beforeClickHandler={this.saveComposition} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    currentOrderActions: bindActionCreators(currentOrderActions, dispatch),
  };
}

const mapStateToProps = state => ({
  device: state.device,
  currentOrder: state.currentOrder,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Upload);

const host = process.env.REACT_APP_URL === 'http://localhost:3000/' ? 'https://webshop-dev.laveniradvertising.be' : window.location.origin;
const url = host + process.env.REACT_APP_API_URL;

const endpoints = {
  getEditions: `${url}/edition?withDates=true&withMunicipalities=true&withProvince=true&withPrices=true&onlyNextDates=true`,
  registerUser: `${url}/user/register`,
  authenticaterUser: `${url}/user/authenticate`,
  getMe: `${url}/user/infos/me`,
  updateMe: `${url}/user/infos/me`,
  addOrder: `${url}/order`,
  payOrder: `${url}/Pay/intent/confirm`,
  createPaypalOrder: `${url}/Pay/paypal/create`,
  confirmPaypalOrder: `${url}/Pay/paypal/confirm`,
  getMunicipalities: `${url}/municipality`,
  currentOrder: `${url}/order`,
  modifyPassword: `${url}/user`,
  contact: `${url}/contact`,
  resetpassword: `${url}/password/reset`,
};

export { endpoints }; /* eslint-disable-line */

export const addMaterial = (orderId, type) => `${url}/advertising/${orderId}/${type}`;
export const getEditionFormat = id => `${url}/edition/${id}?withPrices=true`;
export const getAllOrders = userId => `${url}/order/all/${userId}`;
export const getValidateAccount = (userId, code, validationPage) => `${url}/user/${userId}/activate?activationCode=${code}&redirectTo=${validationPage}`;
